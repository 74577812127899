@use "global" as g; 

//================
// Top
//================
#top {

#kv {
	width: 100%;
	height: 100vh;
	@include g.flex();
	padding: 0;
	color: var(--kvcolor);
	background: var(--kvbg);
	transition: background 0.8s;
	.blockwrap {
		padding: 0;
	}
	.kvcol {
		width: 50%;
		height: 50vh;
	}
	.video-bg {
		iframe {
			width: calc(var(--vh)*50 * (16/9));
		}
	}	
	#kvcol1 {
		order: 3;
		border-right: 1px solid var(--kvcolor);
	}
	#kvcol2 {
		order: 2;
		border-bottom: 1px solid var(--kvcolor);
	}
	#kvcol3 {
		order: 4;
	}
	#kvcol4 {
		order: 1;
		border-right: 1px solid var(--kvcolor);
		border-bottom: 1px solid var(--kvcolor);
		@include g.mq(w1200) {
			font-size: 1.3rem;
		}
		@include g.mq(pc) {
			#abouttxt {
				padding: 0;
			}
		}
	}
	.kvslider {
		@include g.flex();
		padding: 0;
		.box {
			@include g.flex();
			flex-direction: column;
			width: calc(100% / 3);
			padding: var(--gap2);
			line-height: 1;
			border-right: 1px solid var(--kvcolor);
			&:last-of-type {
				border-right: 0;
			}
		}
		.dateelm {
			justify-content: flex-start;
			font-size: 2vw;
		}
		.place {
			justify-content: flex-end;
			align-items: flex-end;
			font-size: 1vw;
			font-weight: 500;
		}
		.swiper {
			padding: 0;
		}
	}
	.kvtitle .swiper-slide,
	.kvabout {
		@include g.flex();
		flex-direction: column;
		justify-content: flex-end;
		padding: var(--gap2);
	}
	.kvtitle .swiper-slide {
		align-items: flex-end;
		font-size: 1vw;
		p {
			display: inline-block;
		}
	}
	// .kvabout {
	// 	@include g.noto();
	// }
}
@include g.mq(sp) {
	#kv {
		height: auto;
		padding-top: 57px;
		.kvcol {
			width: 100%;
			height: 70vw;
			position: relative;
		}
		.video-bg {
			iframe {
				width: calc(70vw * (16/9));
			}
		}
		#kvcol1 {
			height: auto;
			order: 1;
			border-right: 0;
			border-top: 1px solid var(--kvcolor);
			border-bottom: 1px solid var(--kvcolor);
		}
		#kvcol2 {
			order: 2;
			.dateelm {
				border-right: 1px solid var(--kvcolor);
				text-align: left;
			}
			.place {
				align-items: flex-start;
			}
		}
		#kvcol3 {
			order: 3;
			border-bottom: 1px solid var(--kvcolor);
			.swiper {
				border-right: 1px solid var(--kvcolor);
			}
			.place {
				left: auto;
				right: 0;
			}
		}
		#kvcol4 {
			order: 4;
			height: auto;
			border-right: 0;
			border-bottom: 0;
		}
		.kvslider {
			.box {
				width: 50%;
				border-right: 0;
			}
			.dateelm {
				padding: 15px var(--gap2);
				font-size: 2.2rem;
				text-align: right;
			}
			.place {
				position: absolute;
				left: 0;
				bottom: 0;
				font-size: 1.4rem;
				font-weight: 400;
			}
		}
		.kvtitle_txt {
			width: 100%;
			@include g.flex();
			align-items: center;
			font-size: 1.6rem;
			span {
				display: none;
			}
		}
		.kvtitle .swiper-slide,
		.kvabout {
			padding: 15px var(--gap2);
		}
		.kvabout {
			padding: 0;
		}
	}
}

}


//================
// Project
//================
#project {
	padding: 0;
}
#project_list {
	justify-content: flex-start;
	padding: 0;
	.col {
		position: relative;
		border-right: 1px solid #EBEBEB;
		border-bottom: 1px solid #EBEBEB;
		&:nth-of-type(6n){
			border-right: 0;
		}
		a {
			display: block;
			width: 100%;
			height: 15vw;
			position: relative;
			padding: var(--gap2);
			font-weight: 300;
		}
		h3,.cat,.thumb {
			pointer-events: none;
			@include g.tra();
		}
		h3 {
			position: absolute;
			top: var(--gap2);
			left: var(--gap2);
			@include g.machina();
			font-size: 1.6vw;
			font-weight: 300;
			line-height: 1.2;
			color: #000;
			z-index: 2;
			transform-origin: top left;
			svg {
				width: 30px;
				display: none;
				path {
					fill: #000;
				}
			}
		}
		.cat {
			position: absolute;
			bottom: var(--gap2);
			left: var(--gap2);
			font-size: 1.2rem;
		}
		.thumb {
			width: calc(200% - var(--gap2)*2);
			height: calc(150% - var(--gap2)*2);
			position: absolute;
			top: var(--gap2);
			left: var(--gap2);
			@include g.bg();
			// background-size: contain;
			// background-position: top left;
			z-index: 1;
			opacity: 0;
			filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0));
		}			
		&:nth-of-type(6n){
			h3 {
				// transform-origin: top right;
			}
			.thumb {
				left: auto;
				right: var(--gap2);	
				// background-position: top right;
			}
		}
		.sptxt {
			display: none;
		}
	}
	@media (any-hover: hover) {
		.col:hover {
			z-index: 9;
			h3 {
				transform: translate(10px,10px) scale(2);
				svg {
					display: block;
				}
			}
			&:nth-of-type(6n){
				h3 {
					left: calc(-100% + var(--gap2));
				}
			}	
			.thumb {
				opacity: 1;
				filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
			}
		}
	}
	@media (any-hover: none) {
		.col {
			a {
				pointer-events: none;
			}
			&.on {
				z-index: 9;
				h3 {
					transform: translate(10px,10px) scale(2);
					svg {
						display: block;
					}
				}
				&:nth-of-type(6n){
					h3 {
						left: calc(-100% + var(--gap2));
					}
				}	
				.thumb {
					opacity: 1;
					filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
				}
				a {
					pointer-events: auto;
				}	
			}
		}
	}
}
.taglist {
	padding: 10px var(--gap2);
	font-size: 1.2rem;
	border-bottom: 1px solid #EBEBEB;
	.btn {
		display: none;
	}
	ul {
		@include g.flex();
		justify-content: flex-start;
		li {
			margin-right: 20px;
			a {
				padding: 5px 0;
				color: #999;
			}
			a:hover,
			&.current a {
				color: #000;
			}
		}
	}
}

@include g.mq(w980) {
	#project_list {
		.col {
			a {
				height: 24vw;
			}
		}
	}
}

@include g.mq(sp) {
	#project_list {
		.col {
			height: 80px;
			padding: 0;
			overflow: hidden;
			border-right: 0;
			background: #fff;
			&:nth-of-type(6n){
				border-right: 0;
			}
			.sptxt {
				height: 100%;
				display: block;
				position: relative;
				p {
					position: absolute;
					top: 50%;
					left: var(--gap2);
					padding-right: var(--gap2);
					font-size: 1.4rem;
					transform: translate(0,-50%);
					br {
						display: none;
					}
				}
			}
			a.inner {
				position: fixed;
				top: 0;
				height: calc(50vw + 42px);
				padding: 0;
				@include g.flex();
				flex-direction: row;
				justify-content: space-between;
				border-bottom: 1px solid #EBEBEB;
				background: #fff;
				display: none;
				z-index: 80;
				@include g.tra();
				pointer-events: auto;
				h3,.cat,.thumb {
					transition: 0s;
				}
				h3 {
					width: calc(100% - 35vw - var(--gap2)*3);
					top: calc(var(--gap2) + 42px);
					font-size: 5vw;
					transform: translate(0,0);
					br {
						display: none;
					}
					svg {
						@include g.tra();
						display: block;
					}
				}
				.cat {
					width: calc(100% - 40vw - var(--gap2)*3);
					font-size: 1rem;
				}
				.thumb {
					width: 35vw;
					height: 40vw;
					position: absolute;
					top: calc(var(--gap2) + 42px);
					left: auto;
					right: var(--gap2);
					transform: translate(0,0);
					background-size: contain;
					background-position: center right;
					filter: drop-shadow(10px 10px 10px rgba(0, 0, 0, 0.2));
					opacity: 1;
				}
			}
			&:nth-of-type(6n){
				.thumb {
					background-position: center right;
				}
			}
			&:hover {
				h3 {
					transform: translate(0,0);
				}
			}
		}
	}
	body.scrldown a.inner {
		transform: translate(0,-42px);
	}
	.taglist {
	}
}

//================
// Archive
//================
.archives {
	padding-top: 90px;
	#project {
		border-top: 1px solid #EBEBEB;
	}
}


//================
// Single
//================
#single section {
	background: #fff;
}
#singlekv {
	width: 100%;
	height: 100vh;
	padding: 0;
	#kvbg {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	h1 {
		position: absolute;
		top: 50%;
		left: var(--gap2);
		@include g.machina();
		font-size: 5vw;
		line-height: 1;
		color: #000;
		transform: translate(0,-50%);
	}
	#scrldown {
		color: #000;
		path {
			fill: #000;
		}
	}
	@include g.mq(sp) {
		#scrldown {
			display: none;
		}
	}
	
}
body.navwh #singlekv {
	h1 {
		color: #fff;
	}
	#scrldown {
		color: #fff;
		path {
			fill: #fff;
		}
	}
}

#single_contents {
	position: relative;
	background: #fff;
}
#project_info {
	.blockwrap {
		align-items: flex-start;
	}
	.thumb,
	.txt_block {
		width: 50%;
	}
	.thumb {
		position: sticky;
		top: 0;
		padding-right: var(--gap);
	}
	.txt_block {
		font-weight: 300;
		color: #4D4D4D;
		h2,h3 {
			padding-bottom: 10px;
			font-weight: 500;
			color: #000;
		}
		h2 {
			padding-bottom: 0;
		}
		h2 br {
			display: none;
		}
		.txt {
			padding-bottom: 30px;
		}
	}
}

#single_content {
	padding-top: 0;
	.block {
		margin-bottom: 40px;
		padding: 0 40px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	.img_full_block,
	.video_full_block {
		padding: 0;
	}
	.images_block {
		.img {
			width: 100%;
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.img2col {
			@include g.flex();
			.col {
				width: calc((100% - 20px)/2);
				&:first-of-type {
					margin-right: 20px;
				}
			}
		}
	}
}
@include g.mq(sp) {
	#single_content {
		.block {
			margin-bottom: var(--gap);
			padding: 0 var(--gap);
			&:last-of-type {
				margin-bottom: 0;
			}
		}
		.img_full_block,
		.video_full_block {
			padding: 0;
		}	
	}
}

#sec_paging {
	padding: 0;
	border-top: 1px solid #F2F2F2;
	a {
		display: block;
		padding: var(--gap) 0;
		#nextbg {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transition: 0.3s;
			opacity: 0;
			// mix-blend-mode: hard-light;
		}
		&:hover #nextbg {
			opacity: 1;
		}
	}
	.txt_block {
		width: 60%;
		@include g.flex();
		flex-direction: column;
		h3 {
			margin-bottom: var(--gap);
		}
		.tit {
			@include g.machina();
			font-size: 5vw;
			line-height: 1.2;
			color: #000;
			span {
				display: block;
			}
			svg {
				width: 80px;
				path {
					fill: #000;
				}
			}
		}
	}
	.thumb {
		width: 40%;
		padding-left: var(--gap);
	}
}

@include g.mq(sp) {
	#singlekv {
		height: calc(var(--vh)*100);
		h1 {
			font-size: 3.5rem;
		}
	}
	#project_info {
		.thumb {
			position: relative;
			top: auto;
			width: 100%;
			padding: 0;
			padding-bottom: 30px;
		}
		.txt_block {
			width: 100%;
		}
	}
	#sec_paging {
		.txt_block {
			.tit {
				font-size: 2rem;
				svg {
					width: 30px;
					margin-top: 10px;
				}
			}
		}
	}
}


[lang=ja] {
	#top {
		#kvcol4 {
			#abouttxt {
				font-size: 94%;
			}
		}
	}
	#project_info {
		.overview {
			p {
				font-size: 94%;
			}
		}
	}
}


