
$breakpoints: (
	'sp': 'screen and (max-width: 767px)',
	'pc': 'screen and (min-width: 768px)',
	'w1200': 'screen and (max-width: 1200px)',
	'w980': 'screen and (max-width: 980px)',
) !default;

@mixin mq($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}

:root {
	--gap: 8vw;
	--gap2: 20px;
	--kvcolor: #fff;
}
@include mq(sp) {
	:root {
		--gap: 20px;
	}
}

@mixin tra() {
	transition: 0.2s;
}
@mixin flex() {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;	
}
@mixin bg() {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

@mixin noto() {
	font-family: 'Noto Sans JP', sans-serif;
	font-weight: 300;
}
@mixin machina() {
	font-family: 'NeueMachina', sans-serif;
}
