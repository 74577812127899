@use "global" as g; 

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300&display=swap');

@font-face {
	font-family: 'MarkWeb';
	src: url('~/fonts/MarkWeb-regular.woff2') format('woff2'),
			url('~/fonts/MarkWeb-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'MarkWeb';
	src: url('~/fonts/MarkWeb-medium.woff2') format('woff2'),
			url('~/fonts/MarkWeb-medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'NeueMachina';
	src: url('~/fonts/NeueMachina-Light.otf') format('opentype');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'NeueMachina';
	src: url('~/fonts/NeueMachina-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
}

html {
	margin : 0;
	padding : 0;
	font-size:62.5%;
}
body {
	margin : 0;
	padding : 0;
	width : 100%;
	height:100%;
	font-family: 'MarkWeb', sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	color:#000;
	line-height:1.5;
	z-index: 1;
	-webkit-font-smoothing: antialiased;
	/* font-feature-settings : "palt"; */
}

h1,h2,h3,h4,strong,em {
	font-weight: normal;
}
a {
	display: inline-block;
	color:#666;
	text-decoration: none;
	word-break: break-word;
	@include g.tra();
	&:hover{
		color: #000;
	}
}
img {
	width: 100%;
	height: auto;
	display: block;
	vertical-align: text-bottom;
}
li{
	list-style:none;
}
.svg-block {
  display: block;
}
object {
	pointer-events: none;
}
.svg-link object {
	overflow:visible;
}
svg {
	width: 100%;
	height: auto;
}
::selection {
 background: #333;
 color:#fff;
}
::-moz-selection {
 background: #333;
 color:#fff;
}
form ::selection {
 background: #333;
 color:#fff;
}
form ::-moz-selection {
 background: #333;
 color:#fff;
}
:focus {
	outline: 0;
}
.nolink {
	cursor: inherit;
	pointer-events: none;
}
.br_sp {
	display: none;
}
.br_pc {
	display: inline-block;
}

iframe,
video {
	filter: drop-shadow(0px 0px #000);
	outline: none;
	border: none;
}
.video {
	width: 100%;
	padding-top: 56.25%;
	position: relative;
	overflow: hidden;
}
.video iframe,
.video video {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
}

.video-bg {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	iframe {
		width: calc(var(--vh)*100 * (16/9));
		height: 100%;
		min-height: 100%;
		min-width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}
}
@media (min-aspect-ratio: 16/9) {
	.video-bg {
		iframe {
			width: 100%;
			height: calc(var(--vw)*100 * (16/9));
		}
	}
}


/* ***** Common **************** */
.pc { display: inline; }
.sp { display: none; }
@include g.mq(sp) {
	.pc { display: none; }
	.sp { display: inline; }
}

/* ***** Layout **************** */
#maincontents {
}
section {
	position: relative;
	padding: var(--gap) 0;
}
.blockwrap {
	@include g.flex();
	position: relative;
	width: 100%;
	padding: 0 var(--gap);
	.col {
		width: calc(100% / 6);
	}
}
.thin {
	max-width: 800px;
	margin: 0 auto;
}
.block {
	width: 100%;
}

@include g.mq(sp) {
	#maincontents {
	}
	.blockwrap {
		.col {
			width: 100%;
		}
	}	
	.thin {
		max-width: 100%;
	}
	section {
	}
}


