@use "global" as g; 

#nav {
	@include g.flex();
	align-items: center;
	width: 50%;
	position: fixed;
	top: 0;
	left: 0;
	padding: var(--gap2);
	z-index: 99;
	font-size: 1.2rem;
	@include g.tra();
	a {
		color: #000;
	}
}
#nav #nav_logo {
	width: 200px;
	font-size: 0;
	z-index: 99;
	a {
		display: block;
	}
	path {
		fill: #000;
	}
}
@include g.mq(sp) {
	#nav {
		width: 100%;
		padding: 15px var(--gap2);
	}
	#nav #nav_logo {
		width: 110px;
	}
}

body.scrldown #nav {
	transform: translate(0,-101%)
}


/* ----- main_nav ----- */
#main_nav {
	@include g.flex();
	width: calc(100% - 240px);
	padding-bottom: 10px;
	border-bottom: 1px solid #000;
	ul {
		@include g.flex();
		li {
			margin-right: 20px;
			line-height: 1;
			&:last-of-type {
				margin-right: 0;
			}
		}
		a:hover {
			opacity: 0.6;
		}
	}
}
@include g.mq(sp) {
	#main_nav {
		width: calc(100% - 200px);
		padding-bottom: 0;
		border-bottom: 0;
		ul {
			@include g.flex();
			li {
				margin-right: 0;
			}
		}
		#navul {
			width: 100%;
			height: calc(var(--kvvh)*100);
			position: fixed;
			top: 0;
			left: 0;
			flex-direction: column;
			justify-content: center;
			padding: 50px var(--gap2);
			background: #333;
			background: var(--kvbg);
			opacity: 0;
			pointer-events: none;
			transition: 0.3s;
			li {
				width: 100%;
				padding: 10px 0;
				font-size: 1.6rem;
			}
		}
	}
	body.navopen #main_nav #navul {
		opacity: 1;
		pointer-events: auto;
	}
}


/* ----- Bogo ----- */
#bogo {
	position: relative;
	margin-left: auto;
	z-index: 9;
	li:first-of-type {
		margin-right: 5px;
		padding-right: 5px;
		border-right: 1px solid #000;
	}
	.current {
		pointer-events: none;
	}
}
.bogo-language-switcher {
	display: none;
}


/* ----- nav btn ----- */
#nav_btn {
	width: 20px;
	height: 24px;
	position: relative;
	cursor: pointer;
	z-index: 99;
	transition: transform 0.2s;
	display: none;
}
#nav_btn i {
	display: block;
	width: 100%;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 50%;
	background: #000;
	@include g.tra();
}
#nav_btn i:nth-of-type(1) {
	transform: translate(-50%, calc(-50% - 10px));
}
#nav_btn i:nth-of-type(2) {
	transform: translate(-50%,-50%);
}
#nav_btn i:nth-of-type(3) {
	transform: translate(-50%, calc(-50% + 10px));
}
body.navopen #nav_btn i:nth-of-type(1) {
	transform: translate(-50%,-50%) rotate(45deg);
}
body.navopen #nav_btn i:nth-of-type(3) {
	transform: translate(-50%,-50%) rotate(-45deg);
}
body.navopen #nav_btn i:nth-of-type(2) {
	opacity: 0;
}
@include g.mq(sp) {
	#nav_btn {
		display: block;
	}
}

body.navopen {
	overflow: hidden;
}
body.navopen,
body.navopen.navbk,
body.navwh {
	#nav #nav_logo {
		path {
			fill: #fff;
		}
	}
	#main_nav {
		border-bottom: 1px solid #fff;
		a,span{
			color: #fff;
		}
	}
	#bogo {
		li:first-of-type {
			border-right: 1px solid #fff;
		}
	}
	#nav_btn i {
		background: #fff;
	}
}
body.navbk {
	#nav #nav_logo {
		path {
			fill: #000;
		}
	}
	#main_nav {
		border-bottom: 1px solid #000;
		a{
			color: #000;
		}
	}
	#bogo {
		li:first-of-type {
			border-right: 1px solid #000;
		}
	}
	#nav_btn i {
		background: #000;
	}
}
@include g.mq(sp) {
	body.navwh {
		#main_nav {
			border-bottom: 0;
		}
	}
	body.navbk {
		#main_nav {
			border-bottom: 0;
		}
	}
}


/* ***** Footer **************** */
#footer {
	position: relative;
	margin-top: -1px;
	padding: var(--gap2);
	font-size: 1.2rem;
	font-weight: 300;
	color: #666;
	border-top: 1px solid #EBEBEB;
	background: #fff;
	.blockwrap {
		padding: 0;
	}
	strong {
		display: block;
		font-weight: 500;
		color: #000;
	}
	.sns_icon {
		width: 2rem;
		height: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
		path {
			fill: #000;
		}
	}
	#copyright {
		width: 20%;
		min-width: 220px;
		@include g.flex();
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;
		margin-left: auto;
	}
}
@include g.mq(sp) {
	#footer {
		padding: 0;
		font-size: 1.4rem;
		text-align: center;
		.blockwrap {
			min-height: calc(100vh - 79px);
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
		}
		.col {
			width: 100%;
			margin-bottom: 30px;
			&:first-of-type {
				margin-top: 60px;
				margin-bottom: 0;
			}
		}
		strong {
			font-weight: 400;
		}
		.sns_icon {
      position: relative;
      top:auto;
      left: auto;
      transform: translate(0,0);
			margin:14vh auto;
		}	
		#copyright {
			width: 100%;
			display: block;
			font-size: 1.2rem;
			font-weight: 300;
		}
	}
	body.single_page #footer {
		.blockwrap {
			min-height: 0;
		}
	}
}
