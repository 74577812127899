@use "global" as g; 

/*---Animation */
// .anime .obj {
// 	transition: 0.8s;
// 	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
// 	transform: translate3d(0,40px,0);
// 	opacity: 0;
// }
// .anime.active .obj {
// 	transform: translate3d(0,0,0);
// 	opacity: 1;
// }


/*---Flex box */
.flex {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: stretch;
}
.bg {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.fix_img {
	width: 100%;
	position: relative;
	background-attachment: fixed;
}


/* ===== Link Line ===== */
.llc {
	position:relative;
	display: inline-block;
	cursor: pointer;
}
.llc:before{
	content:"";
	display:block;
	height:1px;
	position:absolute;
	top:100%;
	width:100%;
	left:0%;
	right:auto;
	background: #000;
	transition: 0.3s;
}
.ll.active .llc:before,
.ll:hover .llc:before{
	width:0%;
	right:0%;
	left:auto;
	transition:width 0.3s;
}


/* ***** svg hover **************** */
a {
	path {
		@include g.tra();
	}
	&:hover path {
		fill: #3A4F3F;
	}
}


/* ***** scroll down **************** */
#scrldown {
	@include g.flex();
	align-items: center;
	position: absolute;
	left: var(--gap2);
	bottom: var(--gap2);
	color: var(--kvcolor);
	font-size: 1.2rem;
	line-height: 1;
	transform: translate(-100%,0) rotate(90deg);
	transform-origin: right bottom;
	transition: bottom 0.2s;
	z-index: 9;
	&:hover {
		bottom: 0;
	}
	svg {
		width: 80px;
		margin-left: 10px;
		padding-top: 4px;
	}
	path {
		fill:var(--kvcolor);
	}
}
@include g.mq(sp) {
	#scrldown {
		display: none;
	}
}


/* ***** parallax image **************** */
.fullimg {
	position: relative;
	width: 100%;
	padding-top: 50%;
	overflow: hidden;
	.img {
		width: 100%;
		height: 120%;
		position: absolute;
		top: 0;
		left: 0;
		@include g.bg();
	}
	.sp {
		display: none;
	}
}
@include g.mq(sp) {
	.fullimg {
		.pc {
			display: block;
		}
		.sp {
			display: block;
		}
	}
}


/* ***** Title **************** */
.title {
	@include g.flex();
	width: 100%;
	padding-bottom: calc(var(--gap)/2);
	h1,h2 {
		font-size: 1.8rem;
		line-height: 1;
	}
}
@include g.mq(sp) {
}


/* ***** Accordion **************** */
.ex_block {
	.exbtn {
		@include g.flex();
		align-items: center;
		@include g.tra();
		padding: var(--gap2) calc(var(--gap2) * 2);
		cursor: pointer;
		p {
			width: calc(100% - 80px);
			font-size: 1.6rem;
		}
		.icon	{
			width: 20px;
			height: 20px;
			position: relative;
			margin-left: auto;
			&:before,&:after {
				content:'';display: block;
				width: 100%;height: 1px;
				position: absolute;
				top: 50%;left: 50%;
				background: var(--kvcolor);
				transform: translate(-50%,-50%);
			}
			&:after {
				transform: translate(-50%,-50%) rotate(90deg);
				@include g.tra();
			}
		}
	}
	.exc {
		@include g.flex();
		height: 0;
		padding: 0 calc(var(--gap2) * 2);
		overflow: hidden;	
		p {
			width: 100%;
		}
	}
	.open {
		.exc {
			padding-top: var(--gap);
			padding-bottom: calc(var(--gap2) * 2);
		}
		.icon:after {
			transform: translate(-50%,-50%) rotate(0deg);
		}
	}
}
@include g.mq(pc) { // PC //
	.ex_block {
		.exbtn {
			display: none;
		}
		.exc {
			height: auto !important;
			overflow: auto;	
		}
	}
}


/* ***** swiper **************** */
.swiper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	.img {
		width: 100%;
		height: 100%;
		@include g.bg();
	}
}
.swiper-wrapper {
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.swnav {
	display:flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items:center;
}
.swiper-button-next,
.swiper-button-prev {
	width: 20px;
	height: 20px;
	position: relative;
	top: auto;left: auto;right: auto;
	margin: 0;
	opacity: 1;
	transition: 0.3s;
	&:after {
		content:'';display: block;
		width: 100%;height: 100%;
		border-top: 1px solid #000;
		border-right: 1px solid #000;
		transform: scale(1,0.6) rotate(45deg);
	}
	&:hover {
		opacity: 0.6;
	}
}
.swiper-button-prev:after {
	transform: scale(-1,0.6) rotate(45deg);
}
.swiper-pagination-bullet {
	background: none;
	border: 1px solid #fff;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background: #fff;
}


/* ***** Lity **************** */
.lityelm {
	cursor: pointer;
	iframe {
		pointer-events: none;
	}
}


